<template>
  <layout-template>
    <template slot="main">
      <div class="form flex-column">
        <div class="form-title">新增设备</div>
        <div class="form-item flex-column">
          <div class="title">
            设备位置
          </div>
          <div class="field border flex flex-between-center flex-cross-end" >
            <input type="text" name="user_search" placeholder="请选择设备位置" readonly v-model="currentPlaceName" />
            <div class="flex flex-cross-end">
              <div class="flex flex-cross-end" @click="placesSelected=[]">
                <img class="portrait " src="@/assets/clear.svg" v-if="placesSelected.length>0" />
              </div>
              <div class="flex flex-cross-end" @click="onPlaceSelectButtonClick">
                <img class="portrait " src="@/assets/path.svg" v-if="placesSelected.length===0 || (lastPlace && lastPlace.children)" />
              </div>
            </div>
          </div>
        </div>
        <div class="form-item flex-column">
          <div class="title">
            设备SN:
          </div>
          <div class="field border flex flex-between-center flex-cross-end">
            <input type="text" v-model="deviceSn" name="user_search" placeholder="请扫描或输入设备SN" />
            <div class="flex flex-cross-end" @click="deviceSn=null">
              <img class="portrait " src="@/assets/clear.svg" v-if="deviceSn&&deviceSn.length>0" />
            </div>
            <div @click="onScanButtonClick" v-if="supportScan">
                <img class="portrait" src="@/assets/unsplash.svg" />
            </div>
          </div>
        </div>
        <div class="form-item flex-column">
          <div class="title">
            设备名称
          </div>
          <div class="field border flex flex-between-center flex-cross-end">
            <input type="text" name="user_search" placeholder="请设置设备名称（可选）" v-model="deviceName" />
          </div>
        </div>
        <div class="form-item flex-column">
          <div class="title">
            付费设备
          </div>
          <div class="field flex flex-between-center flex-cross-end">
            <base-switch @change="onPaymentButtonClick" :checked="payment"></base-switch>
          </div>
        </div>
        <div class="form-item flex-column">
          <div class="title">
            公共区域
          </div>
          <div class="field flex flex-between-center flex-cross-end">
            <base-switch @change="onAreaButtonClick" :checked="area"></base-switch>
          </div>
        </div>
        <div class="footer flex flex-center">
          <div class="cancel btn flex flex-center" @click="onCancel">取消</div>
          <div class="confirm btn flex flex-center" @click="onCommit">确认</div>
        </div>
      </div>
        <div class="place-selector">
            <transition name="slide-up">
                <div v-show="showSelect" class="select-bar" @click.stop.prevent @dblclick.stop.prevent>
                    <div class="select-header flex flex-between-center">
                        <div class="btn" @click="cancelSelect">取消</div>
                        <div>选择位置</div>
                        <div class="btn" @click="onConfirm">确定</div>
                    </div>
                    <div class="select-content">

                        <vue-scroll  ref="vs"  :ops="{vuescroll:{mode: 'native',sizeStrategy: 'percent',pushLoad:{enable:true}}}">
                            <div  style="overflow: hidden" data-index="-1">
                                <div class="item" v-for="(row,index) in placesOption" :data-index="index" :key="row.id" @click="onTenantTap(row)">
                                    <span>{{row.name}}</span>
                                    <img src="@/assets/yes.svg" class="icon" v-if="currentSelect&&currentSelect.id===row.id"/>
                                </div>
                            </div>
                        </vue-scroll>
                    </div>
                </div>
            </transition>
            <base-confirm :show.sync="showConfirm" :title="'调试设备'" @confirm="onControlDebugConfirm"></base-confirm>
        </div>
    </template>

  </layout-template>
</template>
<script>
import { mapGetters } from 'vuex'
import BaseSwitch from '@/components/base-switch'
import BaseConfirm from '@/components/base-confirm'
import Camera from '@/utils/camera'
import forbidden from '@/assets/forbidden@3x.png'
import wx from 'weixin-js-sdk'
import wechatApi from '@/api/wechat.js'
export default {
  components: { BaseSwitch, BaseConfirm },
  computed: {
    ...mapGetters(['tenantId']),
    currentPlaceName: function () {
      return this.placesSelected.map(p => p.name).join('-')
    },
    lastPlace: function () {
      var [last] = [...this.placesSelected].reverse()
      return last
    }
  },
  props: {
      sn: {
          type: String,
      }
  },
  data: function () {
    return {
      payment: false,
      area: false,
      supportScan: false,
      placesSelected: [],
      placesOption: null,
      deviceName: null,
      deviceSn: this.sn,
      showSelect: false,
      currentSelect: null,
      showConfirm: false,
      device: null,
      url: location.href.split('#')[0],
      forbidden,
      scanStatus: false,
    }
  },
  created () {
    if (this.$isWx) {
      this.supportScan = true
      wechatApi.getConfig({ url: this.url }).then(res => {
          if (res) {
            wx.config({
              debug: this.$DEBUG, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: res.appId, // 必填，公众号的唯一标识
              timestamp: res.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.nonceStr, // 必填，生成签名的随机串
              signature: res.signature, // 必填，签名
              jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
            })
          }
        })
    } else {
      Camera.support().then(r => {
          this.supportScan = r
      })
      console.log('support', this.supportScan)
    }
  },
  methods: {
    onPaymentButtonClick (checked) {
      window.console.log(checked)
      this.payment = !this.payment
    },
    onAreaButtonClick (checked) {
      this.area = !this.area
    },
    onScanButtonClick () {
        if (this.$isWx) {
          this.onqrcode()
        } else {
          navigator.mediaDevices.enumerateDevices().then(d => {
            window.console.log(1, d)
          })
          this.$router.push({ path: '/setup/scan' })
        }
    },
    getSn (sn) {
      if (sn) {
        this.deviceSn = sn
      } else {
        alert('扫描失败，请稍后重试')
      }
    },
    onqrcode () {
      var vue = this
      wx.scanQRCode({
        needResult: 1,
        scanType: ['qrCode', 'barCode'],
        success: function (res) {
          if (res.resultStr) {
            if (res.resultStr.startsWith('CODE_')) {
              res.resultStr = res.resultStr.split(',')[1]
            }
            vue.getSn(res.resultStr)
          }
        },
        error: function (e) {
          console.log(21, e)
        }
      })
    },
    onCancel () {
        this.$router.go(-1)
    },
    onCommit () {
        if (!(this.lastPlace && this.lastPlace.level === 3)) {
          this.$toast.show('设备位置必填，且必须选择一个房间')
          return
        }
        if (this.deviceSn) {
          this.$axios.get(`/h5/device/verifySn/${this.deviceSn}`).then(resp => {
            if (!resp) {
              // 序列号不存在，可以提交
              var form = {
                tenantId: this.tenantId,
                sn: this.deviceSn,
                deviceName: this.deviceName,
                isPay: { value: this.payment ? 0 : 1 },
                isCommonArea: { value: this.area ? 0 : 1 },
                deviceInfo: { place: this.lastPlace },
                pathId: this.lastPlace.id,
                address: this.lastPlace.address,
                addressId: [this.lastPlace.path, this.lastPlace.id].join('-'),
                buildingId: this.placesSelected[0].id,
                isOnline: 0,
                isValid: '1'
              }
              // 构建表单对象
              this.$axios.post('/h5/device/bindDevice', form).then(resp => {
                this.showConfirm = true
              }).catch(error => {
                this.$toast.show(error.msg)
              })
            } else {
              this.$toast.show('序列号已存在，请检查输入是否正确')
            }
          }).catch(error => {
            console.log(error)
            if (error && error.data) {
              this.$toast.show(error.data.msg)
              if (error.data.code === 9010) {
                this.showConfirm = true
              }
            }
          })
        } else {
          this.$toast.show('序列号必填')
        }
    },
    onPlaceSelectButtonClick () {
      if (this.placesSelected && this.placesSelected.length >= 1) {
        var [last] = [...this.placesSelected].reverse()
        this.$axios.get(`/h5/place/getChildrenWithoutChildrenAndParent/${last.id}`).then(resp => {
          this.placesOption = resp
          this.showSelect = true
        })
      } else {
        this.$axios.get(`/h5/place/buildingsWithoutChildren/${this.tenantId}`).then(resp => {
          this.placesOption = resp
          this.showSelect = true
        })
      }
    },
    refresh (done) {
      this.rows = []
      this.pageInfo.pageIndex = 1
      this.noData = false
      this.handleLoadData(done)
    },
    handleRS (vsInstance, refreshDom, done) {
      this.refresh(done)
    },
    handleLS (vm, dom, done) {
      if (this.noData) {
        this.$toast.show('没有更多数据了')
        this.$nextTick(() => done())
      } else {
        this.pageInfo.pageIndex++
        this.handleLoadData(done)
      }
    },
    onTenantTap (place) {
        this.currentSelect = place
    },
    onConfirm () {
        if (this.currentSelect) {
          this.placesSelected.push(this.currentSelect)
        }
        if (this.currentSelect.level === 3) {
          this.showSelect = false
        } else {
          this.currentSelect = null
          this.onPlaceSelectButtonClick()
        }
    },
    cancelSelect () {
        this.showSelect = false
        this.currentSelect = null
    },
    onControlDebugConfirm () {
        // TODO：添加成功后路由到控制界面
        this.$router.push({ name: 'debug', params: { sn: this.deviceSn } })
    }
  }
}
</script>
<style lang="stylus" scoped>
.form {
  height: 100%;
  background-color: #fff;

  .form-title {
    margin-top: 20rpx;
    margin-left: 32rpx;
    color: rgba(0, 0, 0, 0.85);
    font-size: 32rpx;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
  }

  .form-item {
    padding: 26rpx 32rpx;

    .title {
      font-size: 24rpx;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
    }

    .field {
      height: 92rpx;

      &.border {
        border-bottom: solid 1rpx rgba(31, 31, 52, 0.3);
      }

      input {
        width: 100%;
        height: 48rpx;
        line-height: 48rpx;
        outline: none;
        border: none;
        font-size: 32rpx;
      }

      input::-webkit-input-placeholder {
        font-size: 32rpx;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.25);
        text-align: left;
      }

      img{
          width: 48rpx;
          height: 48rpx;
          margin-left: 10rpx;
      }
    }
  }

}
.form
  .footer
    position fixed
    bottom 0
    left 0
    width 100%
    height 120rpx
    background #fff
    transition: all 0.5s
    box-shadow: 0 -4rpx 8rpx rgba(0,0,0,0.05);
    .btn
      width 280rpx
      height 80rpx
      font-size 32rpx
      border-radius 20rpx
      &.cancel
        background #edeff2
        color $menu
        margin-right 40rpx
      &.confirm
        background #1979FF
        color #fff
.place-selector
  .select-bar
    position fixed
    bottom 0
    left 0
    right 0
    .select-header
        font(32,80,$text)
        background #ebebf0
        padding 0 32rpx
        .btn
            color #1979ff
    .select-content
        height 600rpx
        background #fff
        .item
            height 96rpx
            position relative
            text-align: center
            border-bottom 1rpx solid rgba(31,31,52,0.3);
            font(28,96,$text)
            .icon
                position absolute
                top 24rpx
                right 32rpx
</style>
