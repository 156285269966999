<template>
  <transition name="fade">
    <div class="panel-wrapper flex flex-center" v-if="show">
      <div class="panel flex-column" :class="{large: size=='large', 'ex-large': size=='ex-large'}">
        <div class="title">{{title}}</div>
        <div class="content flex-auto"><slot></slot></div>
        <div class="footer">
          <div class="no-select cancel" @click="$emit('cancel');$emit('update:show', false)">{{cancelText || '取消'}}</div>
          <div class="no-select confirm" @click="$emit('confirm');$emit('update:show', false)">{{confirmText || '确定'}}</div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  props: ['show', 'title', 'size', 'cancelText', 'confirmText']
}
</script>
<style lang="stylus" scoped>
.panel-wrapper
  position: fixed
  z-index: 8000
  top: 0
  right: 0
  left: 0
  bottom: 0
  width 100%
  height 100%
  background-color: rgba(0,0,0,.4)
  text-align: center
  .panel
    box-sizing: border-box
    width: 540rpx
    height: 280rpx
    background-color: #fff
    border-radius: 12rpx
    font-size: 32rpx
    .title
      margin-top: 42rpx
      color: $title
      line-height: 44rpx
    .content
      text-align: left
      white-space: pre-wrap
      flex: auto
      margin: 20rpx 40rpx 0
      color: rgba(0,0,0,.65)
      font-size: 28rpx
      line-height: 40rpx
    .footer
      border-top: 2rpx solid $border
      line-height: 86rpx
      height: 86rpx
      display: flex
      .cancel, .confirm
        flex: 1
      .confirm
        border-left: 2rpx solid #dcdee3
    &.large
      width: 686rpx
      height: 326rpx
      border-radius: 16rpx
      margin-top: -50%
      .title
        margin-top: 28rpx
        line-height: 40rpx
        font-size: 28rpx
      .content
        margin: 28rpx 20rpx 0
      .footer
        line-height: 98rpx
        height: 98rpx
    &.ex-large
      width: 686rpx
      height: auto
      border-radius: 16rpx
      .title
        margin-top: 28rpx
        line-height: 40rpx
        font-size: 28rpx
      .content
        margin: 28rpx 20rpx 0
        max-height: 600rpx
        overflow-x: hidden
        overflow-y: scroll
      .footer
        line-height: 98rpx
        height: 98rpx
.cancel
  color $title
.confirm
  color $theme
</style>
